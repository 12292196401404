  @import '../../style/themes/default';
  @import '../../style/mixins';

  @tabs-prefix-cls: am-tabs;
  @tabs-default-bar-prefix-cls: am-tabs-default-bar;
  @badge-prefix-cls: am-badge;

  @easing-in-out: cubic-bezier(0.35, 0, 0.25, 1);
  @effect-duration: .3s;
  @page-hide-color: rgba(255, 255, 255, 0);
  @page-show-color: rgba(255, 255, 255, 1);

  .common-pagination() {
    pointer-events: none;
    position: absolute;
    top: 0;
    display: block;
    width: 59 * @hd;
    height: 100%;
    content: ' ';
    z-index: @tabs-pagination-zindex;
  }

  .@{tabs-prefix-cls} {
    box-sizing: border-box;
    * {
      box-sizing: border-box;
    }

    display: flex;
    flex: 1;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;

    &-content-wrap {
      display: flex;
      flex: 1;
      width: 100%;
      height: 100%;
      min-height: 0;

      &-animated {
        transition: transform @effect-duration @easing-in-out, left @effect-duration @easing-in-out, top @effect-duration @easing-in-out;
        will-change: transform, left, top;
      }
    }

    &-pane-wrap {
      width: 100%;
      flex-shrink: 0;
      overflow-y: auto;
    }

    &-tab-bar-wrap {
      flex-shrink: 0;
    }

    &-horizontal {
      .@{tabs-prefix-cls} {
        &-pane-wrap-active {
          height: auto;
        }

        &-pane-wrap-inactive {
          height: 0;
          overflow: visible;
        }
      }
    }

    &-vertical {
      .@{tabs-prefix-cls} {
        &-content-wrap {
          flex-direction: column;
        }

        &-tab-bar-wrap {
          height: 100%;
        }

        &-pane-wrap {
          height: 100%;
        }

        &-pane-wrap-active {
          overflow: auto;
        }

        &-pane-wrap-inactive {
          overflow: hidden;
        }
      }
    }

    &-top,
    &-bottom {
      flex-direction: column;
    }

    &-left,
    &-right {
      flex-direction: row;
    }
  }

  .@{tabs-default-bar-prefix-cls} {
    position: relative;
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: visible;
    z-index: 1;

    &-tab {
      position: relative;
      display: flex;
      flex-shrink: 0;
      justify-content: center;
      align-items: center;
      font-size: @tabs-font-size-heading;
      height: @tabs-height;
      line-height: @tabs-height;

      .@{badge-prefix-cls} {
        .@{badge-prefix-cls}-text {
          top: -13 * @hd;
          transform: translateX(-5 * @hd);
        }
        .@{badge-prefix-cls}-dot {
          top: -6 * @hd;
          transform: translateX(0);
        }
      }
    }

    &-tab-active {
      color: @tabs-color;
    }

    &-underline {
      position: absolute;
      border: 1px @tabs-color solid;
      // force GPU acceleration
      transform: translate3d(0, 0, 0);
    }

    &-animated &-content {
      transition: transform @effect-duration @easing-in-out;
      will-change: transform;
    }

    &-animated &-underline {
      transition: top @effect-duration @easing-in-out, left @effect-duration @easing-in-out, color @effect-duration @easing-in-out, width @effect-duration @easing-in-out;
      will-change: top, left, width, color;
    }

    &-top,
    &-bottom {
      flex-direction: row;

      .@{tabs-default-bar-prefix-cls} {
        &-content {
          display: flex;
          width: 100%;
          flex-direction: row;
        }

        &-prevpage {
          .common-pagination();

          left: 0;
          background: linear-gradient(to right, @page-show-color, @page-hide-color);
        }

        &-nextpage {
          .common-pagination();

          right: 0;
          background: linear-gradient(to right, @page-hide-color, @page-show-color);
        }

        &-tab {
          padding: 8px 0;
        }

        &-underline {
          bottom: 0;
        }
      }
    }

    &-top {
      .@{tabs-default-bar-prefix-cls} {
        &-tab {
          .hairline('bottom');
        }
      }
    }

    &-bottom {
      .@{tabs-default-bar-prefix-cls} {
        &-tab {
          .hairline('top');
        }
      }
    }

    &-left,
    &-right {
      flex-direction: column;

      .@{tabs-default-bar-prefix-cls} {
        &-content {
          display: flex;
          height: 100%;
          flex-direction: column;
        }

        &-tab {
          padding: 0 8px;
        }
      }
    }

    &-left {
      .@{tabs-default-bar-prefix-cls} {
        &-underline {
          right: 0;
        }
        &-tab {
          .hairline('right');
        }
      }
    }

    &-right {
      .@{tabs-default-bar-prefix-cls} {
        &-underline {
          left: 0;
        }
        &-tab {
          .hairline('left');
        }
      }
    }
  }

@brand-primary: #333333;@primary-button-fill-tap: #222222;